.mobile-sidebar {
  position: fixed;
  top: 0;
  left: -280px;
  width: 70%; 
  max-width: 280px; 
  height: 100%;
  font-size: 0.9rem;
  transition: left 0.3s ease-in-out;
  z-index: 1040;
  padding-top: 30px;
}

.responsive-menu-admin {
    position: fixed;
    bottom: 0;
    top: 58px;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 220px;
    background: linear-gradient(135deg, #ffffff, #fbf6f6, #ffffff);
    color: #ffffff;
    transition: all 0.9s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    height: calc(100vh - 102px);
    overflow-y: auto;
}

.mobile-sidebar.open {
  left: 0;
  height: auto;
}

.mobile-sidebar .nav-link-custom {
  /* color: #fff; */
  padding: 9px;
  display: block;
}

.mobile-sidebar .navbar-nav .nav-link {
  padding-left: 20px !important ;
}

.nav-link-mobile.active{
  width: 100% !important;
}

.navbar-toggler {
  border: none;
  background: transparent;
}

.navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' linecap='round' linejoin='round' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.profile-icon {
  font-size: 1.5rem;
}

.header{
  padding-top: 2.6rem !important;
}

#navbar-main{
  padding: 0.3rem 1rem !important;
}


@media (max-width:768px) {
   .header{
    display: none;
   }
   /* .admin-navbar{
    display: none !important;
   } */
}

.notification {
  position: relative; /* Ensure it's positioned correctly */
  z-index: 10; /* Make sure it appears above other elements */
}



.notification-list {
  max-height: 300px; /* Prevent overflow */
  overflow-y: auto; /* Scroll if necessary */
  background-color: white; /* Background color for contrast */
  border-radius: 8px; /* Rounded corners */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; /* Soft shadow */
}
