._success {
    padding: 35px;
    width: 100%;
    text-align: center;
    border-bottom: solid 4px #28a745;
}

._success i {
    font-size: 55px;
    color: #28a745;
}

._success h2 {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 10px;
}

._success p {
    margin-bottom: 0px;
    font-size: 12px;
    color: #495057;
    font-weight: 500;
}

._failed {
    padding: 45px;
    width: 100%;
    text-align: center;
    border-bottom: solid 4px #dc3545;
}

._failed i {
    font-size: 55px;
    color: #dc3545;
}

._failed h2 {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 10px;
}

._failed p {
    margin-bottom: 0px;
    font-size: 12px;
    color: #495057;
    font-weight: 500;
}


.payment-mode-container {
    margin-bottom: 1rem;
  }
  
  .payment-mode-label {
    display: block;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .payment-mode-options {
    display: flex;
    justify-content: space-around;
  }
  
  .payment-mode-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    border-radius: 8px;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 150px; /* Adjust as needed */
    text-align: center;
  }
  
  .payment-mode-card.selected {
    border-color: #007bff;
    background-color: #e9ecef;
  }
  
  .payment-mode-card:hover {
    border-color: #007bff;
  }
  
  .payment-mode-icon {
    font-size: 2rem; 
    margin-bottom: 0.5rem;
  }
  
  .payment-mode-text {
    font-size: 1rem;
    color: #333;
  }
  
  .payment-mode-radio {
    display: none;
  }
  
  
