/* Footer.css */

.footer {
  display: flex;
  justify-content: center;
  background-color: #000;
  color: #fff;
  padding: 10px 0;
  position: relative;
  width: 100%; 
  height: 100%; 
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
}

.footer-content {
  display: flex;
  flex-direction: column; 
  align-items: center;
  width: 100%;
  max-width: 1200px; 
  margin: 0 auto; 
  text-align: center;
}

.footer-content p {
  margin-bottom: 5px;
  font-size: 16px;
}

.footer-links {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  margin: 5px 0;
}

.footer-links span {
  margin: 0 10px;
}

.social-links {
  display: flex;
  margin-top: 5px;
}

.social-icon {
  color: #bbb; 
  font-size: 20px; 
  margin: 0 10px;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #fff; 
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-content {
    align-items: center; 
  }

  .footer-links {
    flex-direction: row;
    flex-wrap: wrap; 
    justify-content: center; 
    margin: 5px 0; 
  }

  .footer-links span {
    margin: 0 10px;
  }

  .social-links {
    justify-content: center; 
    margin-top: 5px; 
  }
}

/* Larger Screens */
@media (min-width: 992px) {
  .footer-content p {
    font-size: 18px;
  }

  .footer-links span {
    font-size: 16px; 
  }

  .social-icon {
    font-size: 24px; 
  }
}
