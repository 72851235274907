/* Notification Icon and Badge */
.notification-toggle {
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 15px;
}

.notification-icon {
  font-size: 1.4rem;
  color: rgb(237, 174, 17);
  transition: color 0.3s ease;
}

.notification-icon:hover {
  color: rgb(255, 196, 0);
}

.notification-badge {
  position: absolute;
  top: 1px; 
  right: 6px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 1px 5px;
  font-size: 0.6rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 18px;
  height: 18px;
  text-align: center;
}

/* Notification Sidebar */
.notification-sidebar {
  position: fixed;
  top: 0;
  right: -410px;
  width: 400px;
  height: 100%;
  background-color: rgba(39, 39, 39, 0.973);
  color: white;
  transition: right 0.3s ease;
  z-index: 2000;
  padding: 20px;
  box-shadow: -4px 0 15px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
}

/* Media Query for mobile devices */
@media (max-width: 768px) {
  .notification-sidebar {
    width: 70%; 
    right: -80%;
  }
}

.notification-sidebar.open {
  right: 0;
}

/* Overlay */
.notification-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1999;
}

/* Sidebar Header */
.notification-header {
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  text-align: center;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 8px;
}

/* Notification Content */
.notification-content {
  padding-top: 10px;
}

.notification-item {
  position: relative;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: white;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  cursor: pointer;
}

.notification-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.notification-item.removing {
  transform: translateX(100%);
  opacity: 0;
}

.notification-message {
  font-size: 0.7rem;
  color: white;
  font-family: system-ui;
}

.notification-time {
  font-size: 0.6rem;
  color: #bbb;
  margin-top: 4px;
  display: block;
}

/* No Notifications */
.no-notification-item {
  text-align: center;
  padding: 15px;
  color: white;
}

/* Clear Button */
.clear-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #ffffff86;
  background-color: transparent;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.6rem;
}

.clear-button:hover {
  text-decoration: underline;
  color: white;
  background: transparent !important;
  border: none !important;
 
}

/* Clear All Button */
.clear-all-button {
  margin-left: auto;
  color: white;
  border: none;
  padding: 3px 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.7rem;
}

.clear-all-button:hover {
  background-color: #5058548a;
  color: white;
  text-decoration: underline;
}

/* Mark All As Read Button */
.read-all-button {
  color: white;
  border: none;
  padding: 3px 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.7rem;
}

.read-all-button:hover {
  background-color: #5058548a;
  color: white;
  text-decoration: underline;
}

/* Footer */
.notification-footer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-top: 10px;
}

.no-scroll {
  overflow: hidden;
}
