.coupon {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
    margin: auto;
    filter: drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5));
    display: flex;
    align-items: stretch;
    padding: 2px;
    position: relative;
    text-transform: uppercase;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
  }
  
  .coupon::before,
  .coupon::after {
    content: "";
    position: absolute;
    top: 0;
    width: 51%;
    height: 100%;
    z-index: -1;
  }
  
  .coupon::before {
    left: 0;
    background-image: radial-gradient(
      circle at 0 50%,
      transparent 25px,
      var(--coupon-color) 26px
    );
  }
  
  .coupon::after {
    right: 0;
    background-image: radial-gradient(
      circle at 100% 50%,
      transparent 25px,
      var(--coupon-color) 26px
    );
  }
  
  
  .coupon > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .coupon-center {
    flex-grow: 1;
    text-align: center;
    padding: 15px;
  }
  
  .coupon-center h2 {
    color: rgb(6, 6, 6);
    font-size: 1.5rem; 
    white-space: nowrap;
  }
  
  .coupon-box {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    border-radius: 5px;
    margin-top: 10px; 
  }
  
  .coupon-code {
    flex: 1;
    font-size: 1rem;
    font-weight: bold;
    padding: 5px;
  }
  
  .copy-button {
    background-color: #111112;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 0 4px 4px 0 ; 
  }
  
  .copy-button:hover {
    background-color: #d2d3cf;
  }
  
  .copy-button:focus {
    outline: none;
  }

  .top-right-button {
    position: absolute;
    font-size: 0.2rem;
    top: 10px; 
    right: 10px; 
    z-index: 10; 
    background-color: #0b6f44; 
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }  

  .offcanvas-container {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 370px;
    background-color: #f8f9fa;
    box-shadow: -4px 0 6px rgba(0, 0, 0, 0.2);
    transition: right 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .offcanvas-container.open {
    right: 0;
  }
  
  .offcanvas-content {
    padding: 10px 20px;
    height: 100%;
    overflow-y: auto;
  }

  @media screen and (max-width: 1200px) {
    .coupon {
      max-width: 350px;
    }
  }
  
  @media screen and (max-width: 992px) {
    .coupon {
      max-width: 250px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .coupon {
      max-width: 100%;
      height: auto;
    }
  }
  
  @media screen and (max-width: 576px) {
    .coupon {
      max-width: 100%;
      margin: 0 auto; 
      padding: 10px;
    }
  
    .coupon-center h2 {
      font-size: 1.25rem; 
    }
  }
  
  .delete-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    color: red !important;
  }

/* index.css */
.accordion-header h5 {
  font-weight: bold;
  font-size: 1.2rem;
  color: #444;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #007bff;
}

.accordion-body {
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #ffffff;
}

.container {
  max-width: 1200px;
}

/* Settings.css */
.component-container {
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 5px;
  transition: background-color 0.3s ease;
}

.component-container{
  background-color: #d2cece7e; 

}


/* Hover effects */
.component-container:hover {
  background-color: #e6dfdf; /* Subtle hover effect */
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}

.title-text {
  font-family: 'Poppins', sans-serif; /* Stylish font */
  font-weight: bold;
  font-size: 1.1rem;
  color: #362121d3;
}

.expand-arrow {
  font-size: 1.1rem; 
  font-weight: bold;
  color: #250404; 
  transition: transform 0.3s ease; 
}

/* Apply a rotation class conditionally in the component */
.expand-arrow.down {
  transform: rotate(180deg); /* Rotate down arrow when expanded */
}

.expand-arrow.right {
  transform: rotate(0deg); /* No rotation for right arrow */
}


.content-area-coupon {
  margin-top: -1px;
  margin-bottom: 10px;
  overflow: hidden;
  width: 100%;
  height: auto;
 
}
.content-area {
  margin-top: -1px;
  margin-bottom: 5px;
  overflow: hidden;
  width: 100%;
}

.clr-fa {
  color: #41525b; /* Add color to the icons */
}

.coupon.disabled {
  pointer-events: none; /* Prevent clicks on disabled coupons */
}

.expired-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.1); /* Red overlay for expired */
  display: flex;
  align-items: center;
  justify-content: center;
}

.expired-text {
  font-weight: bold;
  text-decoration: line-through;
  color: rgb(219, 19, 19);
  font-size: 1.5rem;
}
