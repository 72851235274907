:root {
  --field-border: 1px solid #eeeeee;
  --field-border-radius: 0.5em;
  --secondary-text: #aaaaaa;
  --sidebar-color: #f1f1f1;
  --accent-color: #2962ff;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-fill {
  display: flex;
  flex: 1 1;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.p-sm {
  padding: 0.5em;
}

.pl-sm {
  padding-left: 0.5em;
}

.pr-sm {
  padding-right: 0.5em;
}

.pb-sm {
  padding-bottom: 0.5em;
}

.p-md {
  padding: 1em;
}

.pb-md {
  padding-bottom: 1em;
}

.p-lg {
  padding: 2em;
}

.m-md {
  margin: 1em;
}

.size-md {
  font-size: 0.85em;
}

.size-lg {
  font-size: 1.5em;
}

.size-xl {
  font-size: 2em;
}

.half-width {
  width: 50%;
}

.pointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.f-main-color {
  color: #2962ff;
}

.f-secondary-color {
  color: var(--secondary-text);
}

.b-main-color {
  background: var(--accent-color);
}

.numbers::-webkit-outer-spin-button,
.numbers::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-back {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
}

.header {
  padding-bottom: 1em;
}

.header .title {
  font-size: 1.2em;
}

.header .title span {
  font-weight: 300;
}

.card-data>div {
  padding-bottom: 1.5em;
}

.card-data>div:first-child {
  padding-top: 1.5em;
}

.card-property-title {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin-right: 0.5em;
}

.card-property-title strong {
  padding-bottom: 0.5em;
  font-size: 0.85em;
}

.card-property-title span {
  color: var(--secondary-text);
  font-size: 0.75em;
}

.card-property-value {
  flex: 1 1;
}

.card-number {
  background: #fafafa;
  border: var(--field-border);
  border-radius: var(--field-border-radius);
  padding: 0.5em 1em;
}

.card-number-field * {
  line-height: 1;
  margin: 0;
  padding: 0;
}

.card-number-field input {
  width: 100%;
  height: 100%;
  padding: 0.5em 1rem;
  margin: 0 0.75em;
  border: none;
  color: #888888;
  background: transparent;
  font-family: inherit;
  font-weight: 500;
}

.timer span {
  background: #311b92;
  color: #ffffff;
  width: 1.2em;
  padding: 4px 0;
  display: inline-block;
  text-align: center;
  border-radius: 3px;
}

.timer span+span {
  margin-left: 2px;
}

.timer em {
  font-style: normal;
}

.action button {
  padding: 1.1em;
  width: 100%;
  height: 100%;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  border: none;
  border-radius: 0.5em;
  transition: background-color 0.2s ease-in-out;
}

.action button:hover {
  background: #2979ff;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
  height: 3em;
  overflow: hidden;
  border: var(--field-border);
  border-radius: var(--field-border-radius);
}

.input-container input,
.input-container i {
  line-height: 1;
}

.input-container input {
  flex: 1 1;
  height: 100%;
  width: 100%;
  text-align: center;
  border: none;
  border-radius: var(--field-border-radius);
  font-family: inherit;
  font-weight: 800;
  font-size: 0.85em;
}

.input-container input:focus {
  background: #e3f2fd;
  color: #283593;
}

.input-container input::placeholder {
  color: #ddd;
}

.input-container input::-webkit-outer-spin-button,
.input-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-container i {
  position: absolute;
  right: 0.5em;
}

.purchase-section {
  position: relative;
  overflow: visible;
  padding: 0 1em 1em 1em;
  background: var(--sidebar-color);
  border-top-left-radius: 0.8em;
  border-top-right-radius: 0.8em;
}

.purchase-section:before {
  content: "";
  position: absolute;
  width: 1.6em;
  height: 1.6em;
  border-radius: 50%;
  left: -0.8em;
  bottom: -0.8em;
  background: #ffffff;
}

.purchase-section:after {
  content: "";
  position: absolute;
  width: 1.6em;
  height: 1.6em;
  border-radius: 50%;
  right: -0.8em;
  bottom: -0.8em;
  background: #ffffff;
}

.card-mockup {
  position: relative;
  margin: 3em 1em 1.5em 1em;
  padding: 1.5em 1.2em;
  border-radius: 0.6em;
  background: #72a2f7;
  color: #fff;
  box-shadow: 0 0.5em 1em 0.125em rgba(0, 0, 0, 0.1);
}

.card-mockup:after {
  content: "";
  position: absolute;
  width: 25%;
  top: -0.2em;
  left: 37.5%;
  height: 0.2em;
  background: var(--accent-color);
  border-top-left-radius: 0.2em;
  border-top-right-radius: 0.2em;
}

.card-mockup:before {
  content: "";
  position: absolute;
  top: 0;
  width: 25%;
  left: 37.5%;
  height: 0.5em;
  background: #2962ff36;
  border-bottom-left-radius: 0.2em;
  border-bottom-right-radius: 0.2em;
  box-shadow: 0 2px 15px 5px #2962ff4d;
}

.purchase-props {
  margin: 0;
  padding: 0;
  font-size: 0.8em;
  width: 100%;
}

.purchase-props li {
  width: 100%;
  line-height: 2.5;
}

.purchase-props li span {
  color: var(--secondary-text);
  font-weight: 600;
}

.separation-line {
  border-top: 1px dashed #aaa;
  margin: 0 0.8em;
}

.total-section {
  position: relative;
  overflow: hidden;

  padding: 1em;
  background: var(--sidebar-color);
  border-bottom-left-radius: 0.8em;
  border-bottom-right-radius: 0.8em;
}

.total-section:before {
  content: "";
  position: absolute;
  width: 1.6em;
  height: 1.6em;
  border-radius: 50%;
  left: -0.8em;
  top: -0.8em;
  background: #ffffff;
}

.total-section:after {
  content: "";
  position: absolute;
  width: 1.6em;
  height: 1.6em;
  border-radius: 50%;
  right: -0.8em;
  top: -0.8em;
  background: #ffffff;
}

.total-label {
  font-size: 0.8em;
  padding-bottom: 0.5em;
}

.total-section strong {
  font-size: 1.5em;
  font-weight: 800;
}

.total-section small {
  font-weight: 600;
}

.payment-methods {
  background-color: #f7f9fc;
  padding: 20px 30px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.payment-title {
  font-size: 22px;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 20px;
}

.payment-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.payment-option {
  gap: 10px;
}

.payment-option label {
  font-size: 16px;
  font-weight: 500;
  color: #34495e;
  cursor: pointer;
}

.cash-dropdown-toggle {
  display: inline-block;
  width: 20vw;
  border: 1px solid #525f7f;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 0.8rem;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cash-dropdown-toggle:hover {
  background-color: #0b0b0b16;
}

.dropdown-arrow {
  color: #525f7f;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #525f7f;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s ease;
}

.cash-dropdown-toggle[aria-expanded="true"] .dropdown-arrow {
  transform: translateY(-50%) rotate(180deg);
}

.cash-dropdown-menu {
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border: none;
  margin-top: 8px;
  padding: 10px 0;
  overflow: hidden;
  background-color: #ffffff;
}

.cash-dropdown-item {
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 500;
  color: #34495e;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-decoration: none;
}

.cash-dropdown-item:hover {
  background-color: #f0f3f7;
  color: #2980b9;
}

.full-page-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.offcanvas-container {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100%;
  width: 330px;
  background-color: #f8f9fa;
  box-shadow: -4px 0 6px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
}

.offcanvas-container.open {
  right: 0;
}

.offcanvas-content {
  padding: 10px 20px;
  height: 100%;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .cash-dropdown-toggle {
    width: 95%;
  }
}


/* cance */
/* Add to your CSS file */
.btn-action {
  min-width: 120px;

  height: 38px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.me-2 {
  margin-right: 0.5rem;
}

.ms-2 {
  margin-left: 0.5rem;
}


/* BookingPage */

.payment-summary-table {
  width: 100%;
  margin-bottom: 1.5rem;
  border-collapse: collapse;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.payment-summary-table th,
.payment-summary-table td {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.table-header {
  background-color: #f8f9fa;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
}

.table-header th {
  text-align: center;
}

.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}

.total-payable-label {
  font-size: 1.25rem;
}

.total-payable-amount {
  font-size: 1.5rem;
  color: #dc3545;
  /* Bootstrap danger color */
}

.coupon-applied-text {
  font-weight: bold;
  color: #28a745;
  /* Bootstrap success color */
}

.coupon-code {
  font-style: italic;
  color: #007bff !important;
  /* Bootstrap primary color */
}

@media (max-width: 768px) {

  .payment-summary-table th,
  .payment-summary-table td {
    padding: 0.75rem;
    font-size: 0.9rem;
  }

  .total-payable-label,
  .total-payable-amount {
    font-size: 1.1rem;
  }
}

.coupon-message {
  color: #28a745;
  font-style: italic;
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
}



/* adminPay */
.payment-status-selection {
  font-family: Arial, sans-serif;
}

.form-check-label {
  font-size: 1rem;
  margin-left: 0.5rem;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.dropdown-menu {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.5rem 0;
}

.dropdown-item {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #e9ecef;
}

.payment-source-toggle .dropdown-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  margin-left: 0.5rem;
}

.btn-sm {
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
}

.btn-success {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}


/* bookingconfirm */
.offcanvas-container {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 400px;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
  z-index: 1050;
  padding: 2px;
  overflow: hidden;
  /* Prevent scrolling */
}

.offcanvas-container.open {
  transform: translateX(0);
}

.offcanvas-content {
  padding: 5px;
  max-height: 100%;
  overflow-y: auto;
}

.offcanvas-container h1 {
  font-size: 1.5rem;
  color: #333;
}

.offcanvas-container .btn.text-xl {
  font-size: 1.5rem;
  color: #000;
  background: none;
  border: none;
}

.form-label {
  font-size: 0.9rem;
  font-weight: 600;
  color: #333;
}

.form-select,
.form-control {
  border-radius: 0.25rem;
  padding: 0.75rem;
  font-size: 0.9rem;
}

.form-select:focus,
.form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

#screenshot {
  padding: 0.5rem;
  border: 2px dashed #ddd;
  background-color: #fafafa;
  border-radius: 0.25rem;
  transition: border-color 0.3s ease-in-out;
}

#screenshot:hover {
  border-color: #007bff;
}

#screenshot+label {
  display: block;
  margin-top: 0.5rem;
  font-size: 0.85rem;
  color: #555;
}

.btn {
  border-radius: 0.25rem;
  padding: 0.5rem 1.5rem;
  font-size: 0.9rem;
  transition: background-color 0.3s ease-in-out;
}

.btn-success {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  background-color: #dc3545;
  color: #fff;
}

.d-flex.align-items-start.justify-content-center {
  margin-top: 2rem;
}

.text-center.mt-7 {
  margin-top: 2rem;
}

.text-center.mt-7 .btn-outline-danger {
  font-size: 0.85rem;
}

.text-muted.mt-2 {
  font-size: 0.8rem;
  color: #777;
}

.custom-modal {
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.custom-modal-body {
  padding: 25px;
  background-color: #ffffff;
  border-radius: 12px;
  color: #333;
  text-align: center;
}

.custom-title {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #28a745;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.custom-icon {
  color: #28a745;
  margin-left: 10px;
}

.custom-message {
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  color: #555;
  margin-bottom: 25px;
}

.custom-bold-text {
  font-family: 'Courier New', monospace;
  font-weight: bold;
  color: #000;
}

.d-flex.align-items-center.justify-content-center.mb-3 .btn {
  margin: 0 0.5rem;
}

.coupon-card {
  border-radius: 10px;
  border: 2px dotted black;
}

.coupon-card:hover {
  transform: scale(1.01);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.coupon-code {
  font-size: 1.75rem;
  font-weight: bold;
  color: #343a40;
}

.select-coupon:hover {
  color: white !important;
}


.select-coupon-btn {
  padding: 12px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.select-coupon-btn:hover {
  background-color: #007bff;
  color: white;
}

.coupon-button {
  border-radius: 0 10px 10px 0 !important;
}

.coupon-close:hover {
  background-color: white;
}


@media (max-width: 768px) {
  .coupon-code {
    font-size: 1.5rem;
  }


  .select-coupon-btn {
    font-size: 0.9rem;
    padding: 10px;
  }
}


.custom-ok-button {
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 1rem;
  color: #fff;
  transition: background-color 0.3s ease;
}

.custom-ok-button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 576px) {
  .custom-modal {
    margin: 10px;
    width: 100%;
  }

  .custom-modal-body {
    padding: 15px;
  }

  .custom-title {
    font-size: 1.25rem;
    flex-direction: column;
    align-items: center;
  }

  .custom-icon {
    font-size: 30px;
    margin-top: 10px;
  }

  .custom-message {
    font-size: 0.875rem;
  }

  .custom-ok-button {
    padding: 10px 20px;
    font-size: 0.875rem;
  }
}

/* billing */
.billing-table-container {
  width: 100%;
  margin: 0px 30px 30px 10px;
  border-radius: 12px;
  overflow: hidden;
  background: #ffffff;
}

.billing-table {
  width: 100%;
  border-collapse: collapse;
}

.billing-table thead {
  background: #34495e;
  color: #ffffff;
}


.billing-table td {
  padding: 15px;
  text-align: right;
  font-family: 'Lato', sans-serif;
}

.billing-table th {
  padding: 10px;
  font-size: small;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.billing-table td:first-child {
  text-align: left;
}

.billing-table tbody tr:nth-child(even) {
  background-color: #f4f6f8;
}

.billing-table tbody tr:hover {
  background-color: #ecf0f1;
}

.billing-table .net-total-label {
  font-weight: bold;
  font-size: 1.2rem;
  color: #34495e;
  text-align: left;
}

.billing-table .net-total-amount {
  font-weight: bold;
  font-size: 1.4rem;
  color: #e74c3c;
}

.billing-table .right-align {
  text-align: right;
}

.billing-table p {
  margin: 0;
  font-size: 1rem;
}

.billing-table em {
  font-style: normal;
  color: #2980b9;
  font-weight: bold;
}

@media (max-width: 768px) {

  .billing-table th,
  .billing-table td {
    padding: 10px;
    font-size: 0.9rem;
  }

  .billing-table .net-total-label,
  .billing-table .net-total-amount {
    font-size: 1.2rem;
  }
}
/* Container styling */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px; /* Add padding around the container */
}

/* Coupon card styling */
.coupon {
  width: 100%;
  height: 130px; /* Adjusted height */
  border-radius: 10px;
  overflow: hidden;
  /* margin: 0 auto; */
  display: flex;
  align-items: stretch;
  position: relative;
  text-transform: uppercase;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
  /* background-image: radial-gradient(circle at 0 50%, transparent 25px, var(--coupon-color) 26px),
    radial-gradient(circle at 100% 50%, transparent 25px, var(--coupon-color) 26px); */
  background-repeat: no-repeat;
  background-size: 50% 100%;
  padding: 10px; /* Add padding */
}

.coupon-center {
  flex-grow: 1;
  text-align: center;
  padding: 15px; /* Adjust padding */
}

/* Coupon code styling */
.coupon-code {
  font-size: 1.25rem; /* Reduced font size */
  font-weight: bold;
  margin-bottom: 5px; /* Reduced margin */
  color: #333;
}

/* Minimum spend styling */
.minimum-spend {
  font-size: 0.9rem; /* Slightly smaller font */
  margin-bottom: 10px; /* Adjusted margin */
  color: #666;
}

/* Select button styling */
.btn.select-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px; /* Adjusted padding */
  border-radius: 5px;
  font-size: 0.75rem; /* Reduced font size */
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn.select-btn:hover {
  background-color: #0056b3;
}

/* Validity styling */
.validity {
  font-size: 0.7rem; /* Adjusted font size */
  color: #666;
  margin-top: 5px; /* Adjusted margin */
}

/* Top-right button styling */
.top-right-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 5px;
}

.top-right-button svg {
  fill: #007bff;
}

/* Apply coupon button styling */
.coupon-button {
  padding: 8px 16px; /* Smaller padding */
  font-size: 0.875rem; /* Smaller font size */
}

/* Close button styling */
.coupon-close {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #666;
  cursor: pointer;
  transition: color 0.2s;
}

.coupon-close:hover {
  color: #ff0000;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .coupon {
    width: 100%;
    height: 120px; /* Smaller height for mobile */
  }

  .coupon-code {
    font-size: 1.1rem; /* Smaller font for mobile */
  }

  .btn.select-btn {
    font-size: 0.75rem; /* Reduced font size for mobile */
  }
}
 
  .code:hover{
  background: green !important;
  color: #fff !important;
  cursor: pointer
  } 
  .code:active{
  background: green !important;
  color: #fff !important;
  cursor: pointer
  }

  
  .circles-container {
    position: absolute;
    top: 0;
    right: -10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  
  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    position: relative;
  }
  
  .calendar-date{
    width: 1000px !important;
    max-width: 1500px !important;
  }