@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');

.login-signup {
	height: 100vh;
	display: flex;
	font-family: Raleway, sans-serif;
}

.mobile-login-signup {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 1rem 0;
}
.captcha-container {
	position: relative; 
  }
  
  .captcha-display {
	position: relative;
	background-color: #2d2d2d; 
	padding: 15px;
	border-radius: 6px;
	margin-bottom: 10px;
	text-align: center; 
	overflow: hidden; 
  }
  
  .captcha-text {
	font-size: 1em;
	color: #ffdd57; 
	position: relative;
	z-index: 1;
	letter-spacing: 3px;
  }
  
.bg-image {
	background-image: url('../../assets/img/brand/Login.avif'); 
	background-size: cover;
	background-position: center;
	height: auto;
	width: 50%;
}

.appForm {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #12130f;
	padding: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	max-width: 300px;
}

.logo {
	margin-top: 20px;
}

.formCenter {
	margin-top: 20px;
}

.formTitle {
	color: #707c8b;
	font-weight: 300;
	margin-bottom: 20px;
	text-align: center;
}

.formField {
	margin: 20px 0;
	padding: 0rem 3rem;
}

.forgot-password-link{
	padding: 0rem 3rem;
}

.formTitleLink {
	color: #707c8b;
	text-decoration: none;
	font-size: 1.2em;
	margin: 0 10px;
	padding-bottom: 5px;
}

.formTitleLink-active {
	color: white;
	border-bottom: 1px solid #6b5b95;
}

.formFieldLabel {
	display: block;
	font-size: 0.8em;
	color: white;
}

.formFieldInput {
	width: 100%;
	background-color: #12130f;
	padding: 0.6rem;
	border: none;
	color: white;
	outline: none;
	border-bottom: 1px solid #445366;
	font-size: 1em;
	font-weight: 300;
}

.formFieldInput::placeholder {
	color: #616e7f;
}

.form-error {
	color: rgba(253, 43, 43, 0.741);
	text-align: left;
	margin-left: 12px;
	margin-top: 3px;
	font-size: 0.7rem;
}

.formFieldButton {
	background-color: #d9cf4e;
	color: rgb(0, 0, 0);
	border: none;
	outline: none;
	border-radius: 25px;
	padding: 15px 70px;
	font-size: 1rem;
	font-weight: 500;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.formFieldButton:hover {
	background-color: #dbcf1f;
}

.formFieldLink {
	color: #66707d;
	text-decoration: none;
	display: inline-block;
	border-bottom: 1.5px solid #6b5b95;
	padding-bottom: 5px;
}

.formFieldLink:hover {
	border-color: white;
}

.app {
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;
}

.background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 120vh;
	background-image: url('../../assets/img/brand/Login.avif');
	background-size: cover;
	background-position: center;
	filter: blur(8px); 
	z-index: -1; 
}

.password-input-container {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
}

.password-toggle-icon {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	color: #ffffff;
}

.content {
	position: relative;
	z-index: 1; 
	background-color: rgba(0, 0, 0, 0.895); 
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.formFieldCheckboxLabel {
	font-size: 0.875rem;
	color: #343a40;
	padding: 0rem 3rem;
}

.formFieldTermsLink {
	color: #007bff;
	text-decoration: underline;
	cursor: pointer;
}

.formFieldTermsLink:hover {
	color: #0056b3;
}

.compact-modal .modal-dialog {
	max-width: 400px; 
	margin: 1.75rem auto; 
}

.terms-modal-header {
	background-color: #000000; 
	padding: 0.75rem 1rem; 
}

.terms-modal-title {
	color: #ffffff !important; 
	font-weight: 600;
	font-size: 1.25rem; 
}

.terms-modal-body {
	background-color: #f8f9fa; 
	padding: 1rem; 
	color: #343a40;
	font-size: 0.75rem; 
	line-height: 1.2; 
	max-height: 300px; 
	overflow-y: auto; 
}

.terms-heading {
	color: #007bff; 
	font-weight: 600;
	margin-top: 0.5rem; 
	font-size: 0.875rem; 
}

.terms-text {
	color: #495057; 
	margin-bottom: 0.4rem; 
	font-size: 0.75rem; 
}

.terms-modal-footer {
	background-color: #f8f9fa;
	padding: 0.25rem 0.5rem; 
	border-top: none;
	display: flex;
	justify-content: flex-end;
	align-items: center; 
	font-size: 0.75rem; 
	height: 40px; 
}

@media (max-width: 750px) {
	.panel-body {
		padding: 1rem 0 !important;
	}
	.captcha-refresh {
		padding: 0.1rem 0.5rem;
	}

	.appForm {
		max-width: 90%; 
	}

	.formFieldButton {
		padding: 10px 40px; 
	}
}
