/* Overlay Styles */
.discount-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Content Styles */
.discount-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 600px;
    max-width: 90%;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Header Styles */
.discount-popup-header {
    margin: 0;
    margin-bottom: 20px;
    font-size: 20px;
    color: #333;
    text-align: center;
}

/* Close Button Styles */
.discount-popup-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: #FF5A5A;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

/* Table Styles */
.discount-popup-table {
    width: 100%;
    border-collapse: collapse;
}

.discount-popup-th {
    background-color: #f4f4f4;
    color: #333;
    font-weight: bold;
    padding: 10px;
    border-bottom: 2px solid #ddd;
}

.discount-popup-td {
    padding: 8px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}
