

 .table-responsive-wrapper {
  max-height: 560px;
  overflow-y: auto;
}

.table-flush thead th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.monthly:active{
  color: white !important;
}
 .yearly:active{
  color: #9da9e8 !important;
} 
.weekly:active{
  color: white !important;
}

@keyframes slide-right {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.graph-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 30%, rgba(255, 255, 255, 0) 60%);
  animation: backgroundAnimation 10s ease-in-out infinite alternate;
}

@keyframes backgroundAnimation {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.05);
    opacity: 0.3;
  }
}


.CardNEW {
  transition: transform 0.3s ease;
}

.CardNEW:hover {
  transform: translateY(-5px);
}

.bar {
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.bar:hover {
  transform: scale(1.1) rotate(3deg);
  background-color: #d63031 !important;
}

.nav-link {
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
}

.nav-link:hover {
  transform: scale(1.05);
  background-color: rgba(255, 255, 255, 0.3);
  color: #d63031;
}


/*Higlighs */

/* Ensure the overall layout is responsive */
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

/* Responsive adjustments for mobile screens */
@media (max-width: 576px) {
  .grid {
    grid-template-columns: 1fr; 
  }

  .custom-card {
    padding: 1rem; 
    height: auto; 
  }

  .text-4xl {
    font-size: 2.5rem; 
  }

  .text-3xl {
    font-size: 2rem; 
  }

  .text-xs {
    font-size: 0.8rem;
  }
}

/* Medium devices */
@media (min-width: 576px) and (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .custom-card {
    padding: 1.5rem;
    height: auto; 
  }

  .text-4xl {
    font-size: 3rem;
  }

  .text-3xl {
    font-size: 2.5rem;
  }

  .text-xs {
    font-size: 0.9rem;
  }
}

/* For larger mobile devices */
@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr); 
  }

  .custom-card {
    padding: 2rem;
    height: auto;
  }

  .text-4xl {
    font-size: 3.5rem; 
  }

  .text-3xl {
    font-size: 2.8rem;
  }

  .text-xs {
    font-size: 1rem; 
  }
}


.legend-item {
  display: flex;
  align-items: center;
  padding: 6px 8px; /* Compact padding */
  background-color: #fff; /* Background for each legend item */
  border-radius: 6px; /* Rounding for legend item box */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

.legend-color-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%; /* Circular shape */
  margin-right: 6px;
}

.legend-label {
  font-size: 12px;
  color: #333;
  font-weight: 500;
}
 