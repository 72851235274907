.payment-select {
  background-color: white !important;
  border: 1px solid #ced4da;
  color: #6c757d;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
  appearance: none;
  background: url("data:image/svg+xml;charset=US-ASCII,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'><path fill='%23999' d='M2 5L0 3h4z'/></svg>") no-repeat right 1rem center;
  background-size: 12px 12px;
  padding-right: 2rem;
  width: 100%;
  border-radius: 0.375rem;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.payment-select:focus {
  border-color: black !important;
}

.payment-select option {
  padding: 0.5rem;
}

.sorting {
  font-size: 0.6rem;
}

.disable-select {
  user-select: none;
}

/* Summary Section Styling */
.summary-section {
  border: 1px solid #ced4da;
  border-bottom: 0;
  /* border-radius: 0.375rem; */
  background-color: #ffffff;
  /* margin-bottom: 0.5rem; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.summary-title {
  font-size: 1rem;
  font-weight: 500;
  color: #343a40;
}

.summary-amount,
.summary-count {
  font-size: 1.25rem;
  font-weight: 700;
  color: #343a40;
  display: flex;
  align-items: center;
}

.icon-size {
  width: 24px;
  height: 24px;
}

.status-success {
  color: green;
}

.status-failed {
  color: red;
}

.status-pending {
  color: grey;
}

/* General Styles */
.payment-card {
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.payment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transaction-id {
  font-size: 0.9rem;
}

.download-btn-mobile,
.download-btn-desktop {
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
}

.hide-when-printing {
  display: none;
}

.details-table,
.methods-table,
.summary-table {
  width: 100%;
  border-collapse: collapse;
}

.details-table th,
.details-table td,
.methods-table th,
.methods-table td,
.summary-table th,
.summary-table td {
  padding: 0.75rem;
  text-align: left;
}

.summary-title {
  color: #525f7f;
}

.cancel-btn {
  border-radius: 0.2rem;
}

.divider {
  margin: 0;
}

.text-right {
  text-align: right;
}

.room-name {
  font-style: italic;
}

.price,
.total,
.subtotal,
.amounts,
.total-label,
.total-amount {
  text-align: center;
}

.total-amount {
  color: #dc3545;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .payment-card {
    margin: 0.5rem;
    padding: 1rem;
  }

  .download-btn-mobile {
    display: block;
  }

  .download-btn-desktop {
    display: none;
  }

  .details-table,
  .methods-table,
  .summary-table {
    font-size: 0.9rem;
  }

  .details-table th,
  .details-table td,
  .methods-table th,
  .methods-table td,
  .summary-table th,
  .summary-table td {
    padding: 0.15rem;
    text-align: left;
  }
}

@media (max-width: 576px) {
  .payment-card {
    margin: 0.25rem;
    padding: 0.5rem;
  }

  .download-btn-mobile {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }

  .details-table,
  .methods-table,
  .summary-table {
    font-size: 0.75rem;
  }
}


/* Modal Styles */
.table-component__modal .modal-dialog {
  width: 400px;
}

.table-component__modal .modal-content {
  padding: 20px;
}

.table-component__modal .modal-header {
  background-color: #f8f9fa;
}

.table-component__modal .modal-footer {
  border-top: 1px solid #dee2e6;
}

/* Column Options */
.table-component__column-options {
  padding: 10px 0;
}

.table-component__select-all {
  margin-bottom: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.table-component__checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.table-component__checkbox {
  margin-right: 5px;
}



.table-component__close-btn {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.table-component__close-btn:hover {
  background-color: #5a6268;
}

/* Layout Adjustments */
.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.ms-auto {
  margin-left: auto;
}

.me-3 {
  margin-right: 1rem;
}

.me-sm-5 {
  margin-right: 3rem;
}

.w-100 {
  width: 100%;
}

.w-md-auto {
  width: auto;
}

   

/* Add Coloumns CSS */

/* Make the modal smaller */
.table-component__modal .modal-dialog {
  width: 400px;
}

.table-component__modal .modal-content {
  padding: 20px; 
}

/* Ensure the "Select All" option stays on top */
.table-component__select-all {
  margin-bottom: 10px;
  font-weight: bold;
  display: flex;
  justify-content: start;
  align-items: center;
}

/* Display the columns in a grid layout to avoid vertical stacking */
.table-component__column-options {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); 
  gap: 10px; 
}



/* Align cancel icon next to the checkbox label */
.cancel-icon {
  color: red;
  margin-left: 10px;
  cursor: pointer;
  font-size: 16px;
}

/* Apply button styling */
.table-component__apply-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.table-component__apply-btn:hover {
  background-color: #0056b3; 
}



/* Ensure the column options don't get too close to modal edges */
.table-component__column-options {
  padding: 10px 0;
}

/* Modal header styling */
.table-component__modal-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

/* Style for the "Select All" checkbox and options */
.table-component__select-all-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* Container for the buttons */
.table-component__button-container {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}

/* Styling for grid layout options */
.table-component__column-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  background-color: #f9f9f9;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.table-component__column-option:hover {
  background-color: #e9ecef; 
}

/* Highlight column on drag over */
/* Style for the draggable column header */
.table-component__table th[draggable] {
  cursor: move;
}

/* Style for columns while dragging */
.table-component__table th.dragging {
  background-color: #f0f0f0;
  border: 2px dashed #007bff;
}

/* Optional: Add some styling for the table */
.table-component__table {
  border-collapse: collapse;
  width: 100%;
}

.table-component__table th, .table-component__table td {
  padding: 12px;
  text-align: left;
}

.table-component__table thead th {
  background-color: #f8f9fa;
}

/* attachemnt */
.modal-dialog {
  max-width: auto; /* Adjust modal width */
}

.modal-body img {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.active-tab {
  font-size: 1.3em;
  color: #000;
  font-weight: 600;
  /* border-bottom: 2px solid #000; */
  padding-bottom: 10px;
  transition: all 0.3s ease-in-out;
}

.inactive-tab {
  font-size: 1.1em;
  color: #6c757d;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
}

.inactive-tab:hover {
  color: #000;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
}

.payment-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
}
