/* Modal styling */
.event-modal .modal-content {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.event-modal .modal-header {
  background-color: black;
  color: white;
  border-bottom: none;
}

.event-modal .modal-title {
  font-weight: bold;
}

/* Form styling */
.event-form {
  padding: 20px;
}

.event-form-group {
  margin-bottom: 15px;
}

.event-form-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.event-form-control {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

/* File upload styling */
.event-file-upload {
  display: flex;
  align-items: center;
  gap: 10px;
}

.event-file-select {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
  cursor: pointer;
  margin-right: 10px;
}

.event-file-select-button {
  font-weight: bold;
  color: black;
}

.event-file-select-name {
  margin-left: 10px;
}

/* Image preview styling */
.event-image-preview {
  margin-top: 10px;
  position: relative;
}

.event-image-preview img {
  max-width: 100%;
  max-height: 150px;
  border-radius: 5px;
}

.event-delete-image-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: white;
  border: none;
  cursor: pointer;
}

.event-delete-image-btn:hover {
  color: red;
}

/* Button styling */
.btn-primary {
  background-color: black;
  border: none;
}

.btn-primary:hover {
  background-color: #333;
}



.photo-preview {
    position: relative;
    display: inline-block;
    width: 50%; 
  }
  
  .preview-img {
    width: 100%; 
    height: auto;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .delete-photo-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
  }
  