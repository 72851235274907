canvas {
  display: block;
  vertical-align: bottom;
}

.add-member-btn {
  background-color: #673ab7;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  transition: background-color 0.2s ease;
  cursor: pointer;
}

.add-member-btn:hover {
  background-color: #5e35b1;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-inner {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
}

.popup-header {
  background-color: black;
  color: white;
  padding: 15px;
  border-radius: 8px 8px 0 0;
  margin: -20px -20px 20px -20px;
  text-align: center;
}

.popup-header h2 {
  margin: 0;
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.popup-body {
  padding: 10px;
}

.form-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  flex-direction: row;
}

.form-group label {
  flex: 1;
  color: #333;
  font-weight: bold;
  text-align: left;
  font-size: 0.9rem;
}

.form-group .input-group {
  flex: 2;
}

.form-group .input-group .form-error {
  color: red;
  margin-left: 12px;
  margin-top: 3px;
}

.form-group .input-group input,
.form-group .input-group select {
  padding: 10px;
  margin-left: 10px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s ease;
  width: 100%;
  color: black;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #673ab7;
}

.btn-container {
  display: flex;
  justify-content: space-between;
}

.btn-container button {
  width: 45%;
  padding: 12px 0;
  border: none;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 4px;
}

.btn-outline-dark {
  color: #212529 !important;
  background-color: transparent !important;
  border: 1px solid #212529 !important;
}

.btn-outline-dark:hover {
  color: #ffffff !important;
  background-color: #212529 !important;
}

.submit-btn {
  background-color: #d4cb5e99 !important;
  color: #fff;
}

.submit-btn:hover {
  background-color: #d9cf4e !important;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .form-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .form-group label {
    margin-bottom: 5px;
    text-align: left;
  }

  .form-group input,
  .form-group select {
    margin-left: 0;
    width: 100%;
  }

  .btn-container {
    flex-direction: column;
    gap: 10px;
  }

  .btn-container button {
    width: 100%;
  }
}

.member-search {
  width: 100%;
  max-width: 300px;
  position: relative;
}

.search-bar {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input {
  width: 100%;
  padding-right: 2.5rem;
}

.search-input:focus {
  border-color: black;
  box-shadow: none;
}

.search-icon {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #aaa;
}

.filter-buttons .btn {
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  .member-search {
    width: 100%;
    max-width: none;
  }

  .filter-buttons {
    flex-wrap: wrap;
  }

  .filter-buttons .btn {
    margin-bottom: 0.5rem;
  }
}

.custom-btn-group .btn:hover {
  color: white !important;
}

.editIcon {
  padding: 5px;
  font-size: 16px;
  color: black;
  margin-left: 16px;
}

.editIcon:hover {
  border-radius: 6px;
  font-size: 16px;
  color: white;
  margin-left: 16px;
  background-color: black;
  padding: 5px;
}

.memdel {
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 18px;
}

.member-details-modal .modal-header-custom {
  background-color: black;
  color: white;
  text-align: center;
}

.modal-body-custom {
  background-color: white;
  padding: 20px;
}

.member-details-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-photo-section {
  flex: 1;
  text-align: center;
}

.profile-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-photo:hover {
  transform: scale(1.05);
}

.vertical-line {
  border-left: 2px solid black;
  height: 100px;
  margin: 0 20px;
}

.member-info {
  flex: 2;
}

.member-info ul {
  list-style-type: none !important;
  padding-left: 0px;
}

.member-info li {
  margin-bottom: 10px;
  font-size: 11px;
}

.member-info li strong {
  display: inline-block;
  width: 140px;
  font-size: 12px;
}



.member-details-profile-photo-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.member-details-profile-photo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.userTextProfile {
  width: 52% !important;
  height: 24vh !important;
  font-size: 6rem;
}

.member-details-profile-photo:hover {
  transform: scale(1.05);
}

.member-details-info {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.member-details-info ul {
  list-style-type: none;
  padding-left: 0;
  width: 100%;
  padding: 0 20px;
}

.member-details-info li {
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.member-details-info li strong {
  font-size: 14px;
  width: 140px;
}

.member-details-content {
  background-color: white;
  width: 70%;
  padding: 20px;
  box-sizing: border-box;
}

.table-container {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.table-compact {
  width: 100%;
  table-layout: fixed;
}

.table-compact th,
.table-compact td {
  font-size: 0.6rem;
  padding: 0.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-compact th {
  text-align: center;
}

.table-compact tbody tr {
  vertical-align: middle;
}

.newCol {
  margin-top: -25px;
}

/* Container for the booking history */
.booking-history-container {
  max-width: 100%;
  overflow: hidden;
}

/* Wrapper to set fixed height and make the table scrollable vertically */
.table-wrapper {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.booking-table-head {
  background-color: #ccc9c9;
  z-index: 1;
  border-bottom: 2px solid #ddd;
}

.booking-table-header {
  font-size: 0.6rem;
  color: #333;
  font-weight: 600;
}

/* Reduce padding for headers and cells */
.booking-table th,
.booking-table td {
  font-size: 0.65rem !important;
  padding: 0.3rem;
  text-align: left;
}

/* Ensure table layout does not cause horizontal scroll */
.booking-table {
  table-layout: fixed;
  width: 100%;

}

.booking-table th,
.booking-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Header cells */
.booking-table th {
  position: relative;

}

/* Header content (text and sort icon) */
.table-header-content {
  display: flex;
  align-items: center;
}

/* Sort icon placement */
.sort-icon {
  margin-left: 0.5rem;
  font-size: 0.55rem;
  color: #666;
}

.table-header-content:hover .sort-icon {
  color: #000;
}

/* Table rows */
.booking-table-row {
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.booking-table-row:hover {
  background-color: #f0f0f0;
}

@media screen and (max-width: 768px) {

  .booking-table-header,
  .booking-table-row {
    font-size: 0.5rem;
  }

  .table-wrapper {
    height: 300px;

  }
}




/* Container Flex Styles */
.custom-btn-group {
  display: flex;
  justify-content: center;
}

/* Button Count Badge Styles */
.badge-count {
  background-color: #ffc107;
  color: #212529;
  font-weight: bold;
  border-radius: 12px;
  padding: 3px 8px;
  font-size: 12px;
  margin-left: 8px;
  vertical-align: middle;
}

/* Button Outline Dark Styles */
.btn-outline-dark {
  color: #212529 !important;
  background-color: transparent !important;
  border: 1px solid #212529 !important;
}

.btn-outline-dark:hover {
  color: #ffffff !important;
  background-color: #0a0b0b !important;
}

/* Active Button */
.btn-dark {
  background-color: #212529 !important;
  color: #ffffff !important;
  position: relative;
}
.btn-dark:hover {
  background-color: #212529 !important;
  color: #ffffff !important;
  position: relative;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .badge-count {
    font-size: 10px;
    padding: 2px 6px;
    margin-left: 5px;
  }
}

@media (max-width: 576px) {
  .custom-btn-group {
    flex-direction: column;
    gap: 10px;
  }
  .badge-count {
    font-size: 9px;
    padding: 2px 5px;
  }
}

/* verifyMemeber */
/* Popup container */
.custom-popup {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.custom-popup-inner {
  background-color: white;
  width: 90%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-popup-header {
  background-color: black;
  padding: 15px;
  border-radius: 8px 8px 0 0;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-popup-header h2 {
  color: white;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}


.custom-popup-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Form group styling */
.custom-form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.custom-form-group label {
  font-size: 14px;
  font-weight: bold;
  color: #333; /* Added color for better visibility */
}

.custom-select,
.custom-form-control {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  transition: border-color 0.3s; /* Smooth transition for border color */
}

.custom-select:hover,
.custom-form-control:hover {
  border-color: #888; /* Change border color on hover */
}

.custom-select:focus,
.custom-form-control:focus {
  border-color: #007bff; /* Change border color on focus for accessibility */
  outline: none; /* Remove default outline */
}

/* Scrollable dropdown with fixed size */
.custom-select-fixed {
  max-height: 150px;
  overflow-y: scroll;
}

/* Input Group */
.input-group {
  display: flex;
  align-items: center;
  gap: 5px; /* Added gap for better spacing between input and buttons */
}

.input-group input {
  flex-grow: 1;
  padding: 10px; /* Ensure input has consistent padding */
  border: 1px solid #ccc; /* Consistent border for input */
  border-radius: 5px; /* Rounded corners for consistency */
  transition: border-color 0.3s; /* Smooth transition for border color */
}

.input-group input:focus {
  border-color: #007bff; /* Highlight input on focus */
  outline: none; /* Remove default outline */
}

/* Add responsive design */
@media (max-width: 600px) {
  .custom-form-group {
    flex-direction: column; /* Stack form elements vertically on small screens */
  }

  .input-group {
    flex-direction: column; /* Stack input group vertically */
    gap: 10px; /* Increase gap for better touch targets */
  }
}


/* Input Group */
.input-group {
  display: flex;
  align-items: center;
}

.input-group input {
  flex-grow: 1;
}

/* Button styles */
.btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .custom-popup-inner {
    width: 95%;
  }
}

.booking-table-row {
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.booking-table-row:hover {
  background-color: #f0f0f0;
}

@media screen and (max-width: 768px) {

  .booking-table-header,
  .booking-table-row {
    font-size: 0.5rem;
  }

  .table-wrapper {
    height: 300px;

  }
}
.tooltips-container {
  position: relative;
  display: inline-block;
}

.tooltips {
  visibility: hidden; /* Initially hide the tooltip */
  width: auto; /* Width of the tooltip */
  height: auto !important;
  background-color: #333; /* Background color */
  color: #fff; /* Text color */
  text-align: center; /* Center align text */
  border-radius: 5px; /* Rounded corners */
  padding: 10px; /* Padding inside the tooltip */
  position: absolute; /* Position relative to container */
  z-index: 1; /* Ensure it's above other elements */
  bottom: 100%; /* Position above the trigger */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%) translateY(-5px); /* Center it and adjust vertically */
  margin-bottom: 10px; /* Space between tooltip and trigger */
  transition: visibility 0s, opacity 0.3s ease, transform 0.3s ease; /* Smooth transition */
  opacity: 0; /* Initially transparent */
}

/* Show tooltip on hover */
.tooltips-container:hover .tooltips {
  visibility: visible; /* Show tooltip */
  opacity: 1; /* Make tooltip opaque */
  transform: translateX(-50%) translateY(0); /* Move into view */
}

/* Tooltip arrow */
.tooltips::after {
  content: '';
  position: absolute;
  top: 100%; /* Position at the bottom of the tooltip */
  left: 50%; /* Center horizontally */
  margin-left: -5px; /* Center the arrow */
  border-width: 5px; /* Arrow size */
  border-style: solid; /* Arrow style */
  border-color: #333 transparent transparent transparent; /* Arrow color */
}
@media (max-width: 768px) {
  .userTextProfile {
    font-size: 2rem;
  }
}