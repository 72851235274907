.profile-style{
  padding: 0px !important;
}
.profile-edit{
  padding: 0.5rem !important;
}
.user-profile {
  padding: 20px;
  max-height: 88vh;
  height: 88vh;
}

.profile-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.eye-toggle-icon {
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
}

.changepass{
  border-right: 1px solid #e2e2e2;
}

.profile-card {
  flex: 1 1 30%;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content-card {
  flex: 1 1 65%;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-body {
  text-align: center;
}   

.profile-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.profile-image {
  height: 250px;
  width: 250px;
}

.avatar-lg-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  height: 150px;
  width: 150px;
  background-color: #f8f9fa;
  border-radius: 50%;
}

.logoutbtn {
  background-color: #dc3545;
  border: none;
  font-size: 1rem;
  padding: 8px 16px;
  cursor: pointer;
}

.user-info {
  text-align: left;
}

.change-password-section {
  text-align: left;
}

@media (max-width: 768px) {
  .user-profile {
    padding: 20px;
    max-height: 125vh;
    height: 125vh;
  }
  .profile-section {
    flex-direction: column;
    align-items: center;
  }

  .profile-card, .content-card {
    flex: 1 1 100%;
    margin-bottom: 20px;
  }

  .profile-image, .avatar-lg-text {
    height: 200px;
    width: 200px;
  }
  card-body {
    padding: 0.5rem !important;
}
}

@media (max-width: 400px) {
  .user-profile {
    padding: 20px;
    max-height: 155vh;
    height: 155vh;
  }
}

.profile-photo-container {
  position: relative;
  width: 100%;
  height: 300px; /* Set the desired height */
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}

.admin-profile-photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
}

.lg-text-admin{
  background-color:#8894a06e; 
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  padding: 10px 0;
}

.change-photo-text {
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .profile-photo-container {
    width: 150px;
    height: 150px;
    padding-bottom: 0;
  }

  .change-photo-text {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .profile-photo-container {
    width: 100%;
    height: 180px;  }

  .change-photo-text {
    font-size: 12px;
  }
}
