.responsive-menu {
  position: fixed;
  bottom: 0;
  top: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 230px; 
  background: linear-gradient(135deg, #000000, #333333, #666666);
  color: #ffffff;
  transition: all 0.9s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: calc(100vh - 50px); 
  overflow-y: auto; 
}

.responsive-menu-content {
  padding: 16px;
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.navigation {
  flex-grow: 1;
  margin-top: 32px;
}
.nav-item {
  position: relative;
}

.nav-linkk {
  background: rgba(255, 255, 255, 0.049);
  border-radius: 8px;
  padding: 0.75rem 0.4rem;
  transition: background 0.3s ease;
}

.nav-linkk:hover {
  background: rgba(255, 255, 255, 0.2);
}

.nav-linkk-divider {
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  margin: 0;
}

.nav-linkk i.fa-circle-chevron-right {
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.3s ease;
}

.nav-linkk:hover i.fa-circle-chevron-right {
  color: rgba(255, 255, 255, 0.75);
}

.nav-icon {
  margin-right: 8px;
}

.logout-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
}

.logout-btn,
.login-btn {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  text-align: center;
  font-size: 1rem;
  background-color: #ff4b5c;
  color: #ffffff;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.logout-btn:hover,
.login-btn:hover {
  background-color: #ff6b7c;
  color: #ffffff;
}

.logout-icon {
  margin-right: 8px;
}

.randomNav:hover {
  background-color: transparent !important;
}

