.user-profile {
  padding: 20px;
  max-height: 88vh;
  height: 88vh;
}

.profile-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.profile-card {
  flex: 1 1 30%;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content-card {
  flex: 1 1 65%;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-body {
  text-align: center;
}   

.profile-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.profile-image {
  height: 250px;
  width: 250px;
}

.avatar-lg-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  height: 150px;
  width: 150px;
  background-color: #f8f9fa;
  border-radius: 50%;
}

.logoutbtn {
  background-color: #dc3545;
  border: none;
  font-size: 1rem;
  padding: 8px 16px;
  cursor: pointer;
}

.change-password-section {
  text-align: left;
}

@media (max-width: 768px) {
  .user-profile {
    padding: 20px;
    max-height: 125vh;
    height: 125vh;
  }
  .profile-section {
    flex-direction: column;
    align-items: center;
  }

  .profile-card, .content-card {
    flex: 1 1 100%;
    margin-bottom: 20px;
  }

  .profile-image, .avatar-lg-text {
    height: 200px;
    width: 200px;
  }
  card-body {
    padding: 0.5rem !important;
}
}

@media (max-width: 400px) {
  .user-profile {
    padding: 20px;
    max-height: 162vh !important;
    height: 160vh !important;
  }
}


