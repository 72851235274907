@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap');

.preloader {
  background-color: #ffffff;
  padding: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  z-index: 99999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flash-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.flash-body {
  position: relative;
  z-index: 0;
  width: 350px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif; /* Apply the imported font */
  font-weight: normal; /* Use normal weight for simplicity */
  background-color: #000000;
  color: #ffffff;
}

.flash-body::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 260%;
  background-color: #000000;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: 
    linear-gradient(45deg, #000000, #8b0000 50%), 
    linear-gradient(135deg, #000000, #b22222 50%);
  animation: rotate 2s linear infinite;
}

.flash-body::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 6px;
  top: 6px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  background: #ffffff;
  border-radius: 5px;
}

.club-name {
  font-weight: 600; /* Semi-bold for visibility */
  text-align: center; /* Center text alignment */
  letter-spacing: 1px; /* Slight spacing for elegance */
}

.text-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
