.member-details-full-page-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
}

.member-details-sidebar {
  background-color: rgb(255, 252, 252);
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  color: rgba(44, 43, 43, 0.736);
}

.member-details-profile-photo-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.member-details-profile-photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.member-details-profile-photo:hover {
  transform: scale(1.05);
}

.member-details-content {
  background-color: white;
  width: 70%;
  padding: 20px;
  box-sizing: border-box;
}

.member-details-back-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
}

.member-details-back-button:hover {
  background-color: darkgray;
}

.head {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.max {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.dropdown-container {
  display: inline-flex;
}

.dropdown-filter,
.dropdown-sort {
  margin-right: 0px;
}

.dropdown-filter .btn {
  border-radius: 5px 0px 0px 5px !important;
}

.dropdown-sort .btn {
  border-radius: 0px 5px 5px 0px !important;
}

.member-search {
  margin-left: auto;
}

.search-bar {
  position: relative;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}


.search-input {
  padding-right: 30px;
}

.memdel {
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 18px;
}



.member-details-modal .modal-header-custom {
  background-color: black;
  color: white;
  text-align: center;
}

.modal-body-custom {
  background-color: white;
  padding: 20px;
}

.member-details-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-photo-section {
  flex: 1;
  text-align: center;
}

.profile-photo {

  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-photo:hover {
  transform: scale(1.05);
}

.vertical-line {
  border-left: 2px solid black;
  height: 100px;
  margin: 0 20px;
}

.member-info {
  flex: 2;
}

.member-info ul {
  list-style-type: none !important;
  padding-left: 0px;
}

.member-info li {
  margin-bottom: 10px;
  font-size: 11px;
}

.member-info li strong {
  display: inline-block;
  width: 140px;
  font-size: 12px;

}


.carousel .slide img {
  object-fit: cover;
  height: 300px;
  width: 100%;
}



.form-check-inline {
  display: flex;
  align-items: center;
}



.btn-success {
  font-size: 0.75rem;
  padding: 0.3rem 0.6rem;
}

/* Table container styling */
.history-table-wrapper {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  margin-top: 15px;
}

/* Fixed table header */
.history-table-head {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  border-bottom: 2px solid #ddd;
}

/* Header styling */
.history-table-header {
  font-size: 0.75rem;
  color: #333;
  font-weight: 600;
  background-color: #f2f2f2;
}

/* Table cell and header styling */
.history-table th,
.history-table td {
  font-size: 0.65rem;
  padding: 0.5rem;
  text-align: center;
}

/* Ensure table layout does not cause horizontal scroll */
.history-table {
  table-layout: fixed;
  width: 100%;
}

/* Column widths and text truncation */
.history-table th,
.history-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Specific column widths to ensure visibility */
.history-table th:nth-child(1),
.history-table td:nth-child(1) {
  width: 15%;
}

.history-table th:nth-child(2),
.history-table td:nth-child(2) {
  width: 20%;
}

.history-table th:nth-child(3),
.history-table td:nth-child(3) {
  width: 15%;
}

.history-table th:nth-child(4),
.history-table td:nth-child(4) {
  width: 25%;
}

.history-table th:nth-child(5),
.history-table td:nth-child(5) {
  width: 25%;
}

/* Table rows */
.history-table-row {
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.history-table-row:hover {
  background-color: #f0f0f0;
}

.text-logo {
  text-transform: uppercase;
  background: black;
  border-radius: 50%;
  color: white;
}

.room-price {
  height: calc(1.37em + 1.21rem + 0.1px) !important;
}

/* Responsive for smaller screens */
@media screen and (max-width: 768px) {

  .history-table-header,
  .history-table-row {
    font-size: 0.6rem;
  }

  .history-table-wrapper {
    height: 300px;
  }
}


/* purpose and price in room details */

/* Room.css */
.list-unstyled {
  padding: 0;
  margin: 0;
}

.list-unstyled li {
  transition: background-color 0.2s ease;
}

.list-unstyled li:hover {
  background-color: #f8f9fa;
  /* Light gray on hover */
}

.list-unstyled li span {
  font-size: 1.1rem;
}

.border-bottom {
  border-bottom: 1px solid #ddd;
  /* Light gray border */
}


.custom-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.custom-purpose-dropdown,
.custom-price-input,
.custom-add-remove {
  padding: 0;
}

/* Custom Spacer */
.custom-spacer {
  display: none;
}

@media (max-width: 576px) {
  .custom-row {
    justify-content: flex-start;
  }

  .custom-purpose-dropdown {
    width: 40%;
  }

  .custom-price-input {
    width: 40%;
  }

  .custom-add-remove {
    width: 10%;
  }

  .custom-spacer {
    width: 5%;
    display: block;
  }
}


.custom-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-purpose-dropdown select {
  width: 100%;
}

.custom-price-input input {
  width: 100%;
}

.custom-add-remove {
  text-align: center;
}

@media (max-width: 576px) {
  .custom-row {
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
  }

  .custom-purpose-dropdown {
    flex: 1;
    margin-bottom: 10px;
  }

  .custom-price-input {
    flex: 1;
    margin-bottom: 10px;
  }

  .custom-add-remove {
    flex: 0.5;
  }

  .custom-add-remove .btn {
    padding: 5px 10px;
    font-size: 14px;
  }

  .custom-spacer {
    display: none;
  }
}

input[type="number"],
select {
  padding: 8px;
  font-size: 14px;
}

.room-slot-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.room-type,
.room-slot-time {
  flex: 1;
  padding: 10px;
}

@media (max-width: 576px) {
  .room-slot-container {
    gap: 10px;
    padding: 0 15px;
  }

  .room-type,
  .room-slot-time {
    flex-basis: 48%;
    padding: 5px;
  }
}

@media (max-width: 768px) {
  .form-label {
    padding-left: 15px;
    padding-right: 15px;
  }

  .form-control,
  .form-check {
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% - 30px);
    margin: 0 auto;
  }

  .custom-row {
    padding-left: 15px;
    padding-right: 15px;
  }

  .room-slot-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .room-type,
  .room-slot-time {
    flex: 1;
    padding-right: 10px;
  }

  .room-type:last-child,
  .room-slot-time:last-child {
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .custom-input {
    width: 80%;
    max-width: 300px;
    margin-left: 20px;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.room-name:hover {
  color: #ff9f61;
  transform: scale(1.05);
  transition: color 0.3s ease, transform 0.3s ease;
}

/* Add basic styling for the layout */
.form-check-inline {
  gap: 10px;
  flex-wrap: wrap;
}

/* Basic layout and toggle styling */
.form-check-inline {
  gap: 10px;
  flex-wrap: wrap;
}

/* Switch styling */
/* Toggle Switch Styles */
.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #4caf50;
}

input:checked+.slider:before {
  transform: translateX(12px);
}

/* Calendar Box Styling */
.future-booking-toggle {
  position: relative;
}

.calendar-box {
  position: absolute;
  top: 10px;
  width: auto;
  left: 30px;
  padding: 10px;
  z-index: 10;
  width: auto;
}


.selected-date {
  font-size: 10px;
  width: auto;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
  text-align: center;
}


.upcoming-bookings-title {
  margin-top: 20px;
  font-size: 20px; /* Increased font size */
  color: #555;
  text-align: center;
}

.booking-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.booking-table th,
.booking-table td {
  border: 1px solid #ddd;
  padding: 12px; /* Increased padding for better visibility */
  text-align: left;
  font-size: 16px; /* Increased font size */
}

.booking-table th {
  background-color: #f4f4f4;
  color: #333;
}

.booking-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.booking-table tr:hover {
  background-color: #f1f1f1;
}

.close-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 12px 15px; /* Adjusted padding */
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  display: block;
  width: 100%;
  font-size: 16px; /* Increased font size */
  transition: background-color 0.3s;
}

.close-button:hover {
  background-color: #218838; /* Darken on hover */
}
