  .success-message {
    background-color: #4caf50;
    color: #fff;
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .error-message {
    background-color: #f44336;
    color: #fff;
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
  }

  .table-wrapper {
    overflow-x: auto;
  }

  .hide-when-printing {
    display: none;
  }

  .receipt-container {
    max-width: 700px;
    margin: 20px auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.company-logo img {
    width: 80px;
}

.receipt-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
}

.company-info {
    font-size: 14px;
    color: #555;
}

/* Customer and Receipt Details */
.details-section {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 14px;
    color: #555;
}

.details-section div {
    width: 48%;
}

.details-title {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}

/* Table Styling */
.table-container {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}

.table-header,
.table-row {
    background-color: #f8f9fa;
}

.table-header th,
.table-row td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
}

.table-row {
    background-color: #fff;
}

.total-row {
    font-size: 18px;
    font-weight: bold;
}

/* Footer */
.footer-note {
    text-align: center;
    font-size: 12px;
    color: #888;
    margin-top: 20px;
}

/* Print Button */
.print-button {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.print-button button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.print-button button:hover {
    background-color: #0056b3;
}

/* Responsive Layout */
@media (max-width: 768px) {
    .details-section {
        flex-direction: column;
    }
    .details-section div {
        width: 100%;
        margin-bottom: 15px;
    }
}