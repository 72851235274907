@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;



* {
  font-family: "Roboto Slab", sans-serif;
}

:root {
  --black: rgb(19, 19, 19);
  --light: #ffffff;
  --dark: rgb(19, 19, 19);
  --gold:#f5bd02;
}
.navitemuser-active:hover{
  color: #9d9a32 !important;
}
button:hover {
  color: rgb(19 19 19) !important;
  background-color: #e5eafd !important;
  border: 1px solid rgb(19 19 19) !important;
}
DropdownItem:hover {
  color: rgb(19 19 19) !important;
  background-color: #e5eafd !important;
}
.custom-navbar {
  margin-top: 0 !important;
}
.nav-link-custom {
  transition: background-color 0.3s, color 0.3s;
  color: var(--light) !important;
}

.nav-link-custom:hover {
  background-color: #e5eafd;
}

.nav-link-custom.active {
  background-color: #e5eafd;
  width: 94%;
  color: #000000 !important;
  font-weight: bold;
  border-right: 4px solid #001669;
}

.nav-link-custom i.active {
  background-color: #e5eafd;
  color: #000000 !important;
}

.page-item.active .page-link {
  z-index: 1;
}

.room-item {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.room-item:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.room-item img {
  filter: brightness(90%);
  transition: filter 0.2s;
}

.room-item img:hover {
  filter: brightness(100%);
}

.form-control:focus {
  border-color: #14013e;
  outline: none;
  box-shadow: none;
}

.is-invalid {
  border-color: #dc3545 !important;
  box-shadow: none;
}

.invalid-feedback {
  color: #dc3545;
}

.form-check-input:focus {
  box-shadow: none;
}

.custom-file-input {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  background-color: #fff;
  color: #495057;
}

.custom-file-input:focus {
  border-color: #14013e;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgb(255, 255, 255);
}

.form-select {
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  padding: 0.5rem 1.25rem;
  font-size: 1rem;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.form-select:focus {
  border-color: #00152c;
  outline: none;
  box-shadow: none;
}

.disabled-link {
  pointer-events: none;
  text-decoration: none;
}

.disabled-link:hover {
  color: #ffffff;
}

.disabled-link:active {
  color: #ffffff;
}

.bottom-navbar {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: none;
}
.bottom-btn:hover {
  color: black !important;
}
.bottom-btn:active {
  color: black !important;
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-lg-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  /* Adjust the size as needed */
}

.text-center {
  text-align: center;
}

@media (max-width: 768px) {
  .bottom-navbar {
    display: flex;
  }
}

/* Filter Form Styling */
.filter-form {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.table {
  margin-bottom: 0;
}

.thead-light th {
  font-size: 1rem;
  font-weight: bold;
}

/* Loading Spinner */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner-grow {
  width: 2rem;
  height: 2rem;
}

/* date range */
.inputBox {
  border: 1px solid var(--black);
  padding: 0.5rem 1rem;
}

/* dropdown */
select {
  appearance: none;
  border: 1px solid rgb(227, 221, 221);
  outline: 0;
  font: inherit;
  width: 100%;
  padding: 0.5rem 1rem;
  color: rgb(168, 158, 158);
  border-radius: 0.25em;
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

/* Remove focus outline */
select:focus {
  outline: none;
}

/* Hero.css */
.hero {
  background: linear-gradient(
    to bottom right,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.3)
  );
}

.search-container {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.search-button {
  transition: transform 0.2s, box-shadow 0.2s;
}

.search-button:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Filter Style */
.filter-container {
  display: flex;
}

.filter-sidebar {
  flex: 0 0 25%;
}

.filter-content {
  flex: 1;
  padding: 0px 20px 20px;
}

/* .row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 1.5rem;
} */

.col-sm-12,
.col-5,
.col-2 {
  padding: 0 0.5rem;
}

.select-container {
  position: relative;
}

.select-container::after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: transform 0.2s;
}

.search-button:hover {
  transform: scale(1.05);
}

.blog-img-tab img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.blog-content-tab h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.blog-content-tab p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.blog-content-tab p small {
  font-size: 0.875rem;
}

.blog-desic {
  margin: 1rem 0;
}

.blog-desic::after {
  content: "...";
}

.blog-singe {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blog-singe:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.card {
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}

.card ul {
  padding-left: 0;
}

.card li {
  list-style-type: none;
}

/* UserProfile.css */
@media (max-width: 750px) {
  .mt-mobile {
    margin-top: 1.5rem !important;
  }
}

/* flatipicker */

.calendar {
  display: none;
}

.flatpickr-day.flatpickr-disabled{
  color: rgb(90 68 68 / 40%) !important;
}
.flatpickr-day.flatpickr-disabled:hover {
  cursor:not-allowed !important
}

.flatpickr-day.open{
  color: #3fa46a;
}

.flatpickr-day.flatpickr-disabled.booked{
  color: #fa085d !important;
}
.flatpickr-day.flatpickr-disabled.booked:hover {
  cursor:not-allowed !important
}

.flatpickr-current-month input.cur-year{
  color: white;
}
.custom-flatpickr {
  appearance: none;
  background: url("data:image/svg+xml;charset=US-ASCII,<svg xmlns='http://www.w3.org/2000/svg' fill='%23999' viewBox='0 0 24 24'><path d='M19 3h-1V1h-2v2H8V1H6v2H5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H5V9h14v12zm0-14H5V5h14v2z'/></svg>")
    no-repeat right 0.6rem center;
  background-size: 15px 15px;
  padding-right: 3rem;
  background-color: white !important;
   width: 100% !important;
  color: black;
  padding: 0.5rem 1rem;
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month svg {
  fill: #ffffff !important;
}

.custom-flatpickr:focus {
  border-color: #000000 !important;
}

.carousel.carousel-slider .control-arrow {
  background-color: black;
  height: 2.7rem;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* photo upload input field */
.file-upload {
  display: block;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.file-upload .file-select {
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}

.file-upload .file-select .file-select-button {
  background: #dce4ec;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.file-upload .file-select .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}

.file-upload .file-select:hover {
  border-color: #34495e;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload .file-select:hover .file-select-button {
  background: #34495e;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload.active .file-select {
  border-color: #3fa46a;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload.active .file-select .file-select-button {
  background: #3fa46a;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload .file-select input[type="file"] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.file-upload .file-select.file-select-disabled {
  opacity: 0.65;
}

.file-upload .file-select.file-select-disabled:hover {
  cursor: default;
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}

.file-upload .file-select.file-select-disabled:hover .file-select-button {
  background: #dce4ec;
  color: #666666;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.file-upload .file-select.file-select-disabled:hover .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}

.page-item.active .page-link {
  background-color: #000000;
  border-color: #000000;
}

/* change profilephoto */
.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.dropzone img {
  max-width: 100%;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
}

.dropzone.no-files {
  border-color: #00000059;
}

.text-avatar {
  width: 35px;
  height: 35px;
  display: flex;
  text-transform: uppercase;
  background: black;
  text-align: center;
  border: 2px solid white;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: white;
}

.text-avatar-lg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  font-size: 35px;
  text-transform: uppercase;
  background: black;
  text-align: center;
  border: 2px solid white;

  align-items: center;
  justify-content: center;
  color: white;
}

/* Radio button */
input[type="radio"] {
  --s: 1em;
  --c: #000000;

  height: var(--s);
  aspect-ratio: 1;
  border: calc(var(--s) / 8) solid #939393;
  padding: calc(var(--s) / 8);
  background: radial-gradient(farthest-side, var(--c) 94%, #0000) 50%/0 0
    no-repeat content-box;
  border-radius: 50%;
  outline-offset: calc(var(--s) / 10);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-size: inherit;
  transition: 0.3s;
}
input[type="radio"]:checked {
  border-color: var(--c);
  background-size: 100% 100%;
}

input[type="radio"]:disabled {
  background: linear-gradient(#939393 0 0) 50%/100% 20% no-repeat content-box;
  opacity: 0.5;
  cursor: not-allowed;
}

@media print {
  input[type="radio"] {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
    background: none;
  }
}
.dropdownItem:hover {
  background-color: #00000013;
}

.logoutbtn:hover {
  background-color: white;
  border: 2px solid #f5365c;
  color: #f5365c !important;
}
.logoutbtn:active {
  background-color: white !important;
  border: 2px solid #f5365c;
  color: #f5365c !important;
}

/* Modal Styling */
.modal-header {
  border-bottom: none;
}

.modal-body {
  padding: 0.5rem;
}

.modal-body .card {
  border: none;
}

.modal-body h3 {
  font-size: 1.25rem;
}

.modal-body h4 {
  font-size: 1rem;
}

.modal-body p {
  margin: 0;
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgb(255, 255, 255);
}

.search-bar {
  display: flex;
  align-items: center;
}

.search-input {
  width: 100%;
}

.search-input:focus {
  border-color: black;
  box-shadow: none;
}

.search-icon {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #aaa;
}

.roomsearch-input {
  width: 100%;
  background-color: rgb(19 19 19);
  color: white;
  /* padding-right: 2.5rem; */
}

.roomsearch-input:focus {
  box-shadow: none;
  background-color: rgb(19, 19, 19);
}

.modify-btn {
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 1px solid rgb(184, 115, 3);
  border-radius: 50%;
  padding: 0;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
}

.dropdown-sort-user {
  background-color: rgb(19, 19, 19) !important;
  color: white !important;
  border: none !important;
  width: 130% !important;
}
.dropdown-sort-user:focus {
  color: white !important;
}

.order {
  font-size: 0.875rem;
  color: #8e8f90;
}

.flatpickr-calendar {
  box-shadow: none;
}

.flatpickr-day.selected {
  background-color: #000000;
}

.flatpickr-months .flatpickr-month {
  background-color: #000000;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background-color: #000000;
  color: white;
}
.flatpickr-weekdays {
  background: #000000;
}
span.flatpickr-weekday {
  background-color: #000000;
  color: white;
}

.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background-color: rgb(19 19 19);
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  background: #131313;
  border-color: #131313;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange {
  background-color: #131313;
  border-color: #131313;
}

.flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  background-color: rgb(19 19 19);
  border-color: rgb(19 19 19);
}

.flatpickr-monthDropdown-months option:hover {
  background-color: yellow !important;
  background: yellow !important;
  color: black;
}

.booked{
  color : rgb(239 68 68) !important;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}




