@mixin button-variant($background, $border, $hover-background: darken($background, 0%), $hover-border: darken($border, 0%), $active-background: darken($background, 10%), $active-border: darken($border, 0%)) {
    color: color-yiq($background);
    @include gradient-bg($background);
    border-color: $border;
    @include box-shadow($btn-box-shadow);

    @include hover {
        color: color-yiq($hover-background);
        @include gradient-bg($hover-background);
        border-color: $hover-border;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show>&.dropdown-toggle {
        color: rgb(19 19 19);
        background-color: rgb(217 207 78);
    }
}

@mixin button-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
    color: $color;
    background-color: transparent;
    background-image: none;

    &:hover {
        color: $color-hover;
        background-color: $active-background;
    }

    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show>&.dropdown-toggle {
        color: color-yiq($active-background);
        background-color: $active-background;
    }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
    padding: $padding-y $padding-x;
    font-size: $font-size;
    line-height: $line-height; // Manually declare to provide an override to the browser default
    @if $enable-rounded {
        border-radius: $border-radius;
    }
    @else {
        border-radius: 0;
    }
}