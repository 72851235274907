@keyframes flip-in {
  from {
    opacity: 0;
    transform: rotateX(-90deg);
  }

  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

.dropdown-filter-status {
  position: relative;
  display: inline-block;
  border: none;
}

.dropdown-filter-menu {
  display: none;
  position: absolute;
  background-color: #ffffff;
  width: 95%;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  opacity: 0;
  transform: rotateX(-90deg);
  transform-origin: top;
  animation: flip-in 0.7s ease forwards;
}

.dropdown-filter-status:hover .dropdown-filter-menu {
  display: block;
}

.dropdown-filter-menu a {
  color: #333;
  padding: 10px 15px;
  text-decoration: none;
  display: block;
  font-size: 14px;
  border-bottom: 1px solid #eee;
}

.dropdown-filter-menu a:last-child {
  border-bottom: none;
}

.dropdown-filter-menu a:hover {
  background-color: #f8f9fa;
}

.dropdown-filter-status>button:hover {
  background-color: #00040800;
  color: white;
}

/* badge */
.badge-container {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem;
}

.badge-text {
  background-color: #f1f1f1;
  color: #6c757d;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
}

.btn-close {
  background: transparent;
  border: none;
  font-size: 1rem;
  color: #6c757d;
  margin-left: 0.5rem;
  cursor: pointer;
}

.btn-close:hover {
  color: #495057;
}


/* Container for the status count */
.status-count-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
  /* Adjust spacing as needed */
}

/* Style for the status count */
.status-count {
  font-size: 1.2rem;
  /* Increase font size for visibility */
  font-weight: bold;
  background-color: #f9f9f9;
  /* Slightly different background for visibility */
  border: 2px solid #ccc;
  border-radius: 8px;
  /* Slightly larger border radius */
  padding: 15px 25px;
  /* Increase padding for larger size */
  position: relative;
  overflow: hidden;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  /* Adjust width for larger container */
  height: 60px;
  /* Adjust height for larger container */
  margin-right: 10px;
  /* Space between status counts */
}

/* Container for sliding items */
.status-items-wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  /* Full width of the container */
  overflow: hidden;
  /* Hide overflow */
}

/* Style for each status item */
.status-item {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  position: absolute;
  width: 100%;
  height: 100%;
  animation: slide 4s linear infinite;
  /* Increased duration for better effect */
}

/* Animation for sliding text */
@keyframes slide {
  0% {
    transform: translateX(100%);
  }

  25% {
    transform: translateX(0);
  }

  75% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* Animation delay for each status item */
.status-item.pending {
  animation-delay: 0s;
}

.status-item.modify {
  animation-delay: 2s;
  /* Adjust delay to match animation duration */
}

/* Sidebar Header */
.offcanvas-header {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  /* margin-bottom: 5px; */
  border-bottom: 1px solid rgba(11, 11, 11, 0.471);
  padding-bottom: 2px;
}

/* Notification Content */
.offcanvas-content {
  padding-top: 10px !important;
}

.offcanvas-item {
  background-color: transparent;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(11, 11, 11, 0.471);
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.offcanvas-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
  /* Light hover effect */
  transform: scale(1.05);
  /* Slight scaling effect */
}

.offcanvas-message {
  font-size: 0.85rem;
  color: white;
  font-weight: bold;
}

.offcanvas-time {
  font-size: 0.75rem;
  color: #bbb;
  margin-top: 5px;
}


/* Notification Sidebar */
.offcanvas-sidebar {
  position: fixed;
  top: 0;
  right: -370px;
  /* Hidden by default */
  width: 350px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  transition: right 0.3s ease;
  z-index: 2000;
  padding: 20px;
}

.offcanvas-sidebar.open {
  right: 0;
  /* Sidebar slides in when open */
}

.offcanvas-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255); /* Semi-transparent background */
  z-index: 1999;
  display: none;
}

.offcanvas-overlay.open {
  display: block;
}

.refund-header {
  text-align: center;
  margin-bottom: 20px;
  color: #4a4a4a;
}

.refund-header h1 {
  font-weight: 500;
}

.icon-display {
  font-size: 22px;
  color: #28a745;
  margin-right: 10px;
}

.refund-details {
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.refund-button {
  background-color: #28a745 !important;
  border: none;
}

.refund-button:hover {
  background-color: #218838;
}

.status-info {
  background-color: #e9f7ef;
  border-left: 5px solid #28a745;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.status-info i {
  margin-right: 10px;
  color: #28a745;
  font-size: 20px;
}

/* userRefund */
/* Compact Booking Card */
.compact-booking-card {
  background-color: #788ea6;
  color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  width: auto;
  padding: 10px;
  position: relative;
  overflow: hidden;
}

.compact-booking-card:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.compact-booking-card h2 {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 1rem;
}

.detail-item {
  display: flex;
  align-items: center;
  /* Ensures items align in the center vertically */
  justify-content: space-between;
  /* Ensures label and value are aligned on the same line */
  margin-bottom: 2px;
  margin-top: -5px;
}

.detail-item .icon {
  font-size: 1rem;
  margin-right: 6px;
  color: #ffdd57;
}

.detail-item .label {
  font-size: 0.85rem;
  font-weight: 500;
  flex: 1;
  /* Ensures the label takes the available space */
  text-align: left;
  margin-top: -30px;
}

.detail-item .value {
  font-size: 0.85rem;
  font-weight: 600;
  margin-top: -30px;
  text-align: right;
  /* Aligns the value to the right */
}

.compact-booking-card i {
  width: 24px;
  text-align: center;
}

.compact-booking-card:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transform: skewX(-30deg);
  transition: all 0.5s ease;
}

.compact-booking-card:hover::before {
  left: 100%;
}

.detail-item:last-child {
  margin-bottom: 0;
}


/* edit booking */



.cancel-text {
  font-size: 0.95rem;
  margin-right: 10px;
}



.buttons .update-btn {
  background: #28a745;
  border: none;
  padding: 8px 12px;
  color: #fff;
  transition: background 0.3s ease;
}

.buttons .update-btn:hover {
  background: #218838;
}

.buttons .cancel-modal-btn {
  background: #dc3545;
  border: none;
  padding: 8px 12px;
  color: #fff;
  transition: background 0.3s ease;
}

.buttons .cancel-modal-btn:hover {
  background: #c82333;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .offcanvas-container {
    max-width: 100%;
  }

  .dropdown-filter-menu{
    right: 7px;
  }

  .header .title {
    font-size: 1.3rem;
  }

  .details-card {
    padding: 15px;
  }
}

.offcanvas-content {
  padding: 20px !important;
}